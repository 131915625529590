import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  getGridTranslations,
  getTranslations,
} from "../../../../translations/Translations";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import {
  ContactsRouterResponse,
  EventRouterResponse,
  FieldRecord,
  Filter,
  INewRecord,
  IRecord,
  ValidationTypes,
} from "../../../../interfaces/Interfaces";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  MuiEvent,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { alpha, getContrastRatio } from "@mui/material/styles";
import ContactImportControl from "./ContactImportControl";
import { useFormik } from "formik";
import Registrationfield from "../../../../components/Registrationfield";
import SaveBar from "../../../../components/SaveBar";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import MailIcon from "@mui/icons-material/Mail";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BlockIcon from "@mui/icons-material/Block";
import FlagIcon from "@mui/icons-material/Flag";
import {
  localeFormatDateTime,
  parseDateTime,
} from "../../../../helpers/DateFunctions";
import useDebounce from "../../../../helpers/useDebounce";
import { buildYupStringSchema } from "../../../../helpers/Helpers";

interface RegistrationsProps {
  routeLoader?: string;
  registered?: boolean;
  waitinglist?: boolean;
  adminApproval?: boolean;
  adminApprovalRejected?: boolean;
}

function Registrations({
  registered = true,
  routeLoader = "registrations",
  waitinglist = false,
  adminApproval = false,
  adminApprovalRejected = false,
}: RegistrationsProps) {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const gridtranslations = getGridTranslations();
  const [filteredSessions, setFilteredSessions] = useState<IRecord[]>();
  const { registrations, sessions, fields, eventId, registrationWidgetId } =
    useRouteLoaderData(routeLoader) as ContactsRouterResponse;
  const revalidator = useRevalidator();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmtitle, setConfirmtitle] = useState<string>("");
  const [confirmmessage, setConfirmmessage] = useState<string>("");
  const [confirmFunction, setConfirmFunction] = useState<Function>(
    () => () => {}
  );
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [id, setId] = useState<number>(-1);
  const [myDialogTitle, setMyDialogTitle] = useState<string>(
    translations["contacts.newcontacttitle"]
  );
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [attendanceFilter, setAttendanceFilter] = useState<IRecord | null>(
    null
  );
  const [activityFilter, setActivityFilter] = useState<IRecord | null>(null);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [originalRows, setOriginalRows] = useState<IRecord[]>(
    registrations.map((reg) => {
      return {
        id: reg.id,
        attendances: reg.attendances,
        registered: reg.registered,
        activities: reg.activities,
        flag: reg.flag,
        ...reg.values,
      };
    })
  );
  const [rows, setRows] = useState<IRecord[]>(
    registrations.map((reg) => {
      return {
        id: reg.id,
        attendances: reg.attendances,
        registered: reg.registered,
        activities: reg.activities,
        flag: reg.flag,
        ...reg.values,
      };
    })
  );

  const firstnameField = fields.find(
    (field) => field.defaultfield === "firstname"
  );
  const lastnameField = fields.find(
    (field) => field.defaultfield === "lastname"
  );
  const groupField = fields.find((field) => field.defaultfield === "group");
  const emailField = fields.find((field) => field.defaultfield === "email");
  const nameFilter = (record: IRecord, value: string) => {
    const searchLower = value.toLocaleLowerCase();
    return (
      (firstnameField &&
        record["field" + firstnameField.id]
          ?.toLocaleLowerCase()
          .includes(searchLower)) ||
      (lastnameField &&
        record["field" + lastnameField.id]
          ?.toLocaleLowerCase()
          .includes(searchLower)) ||
      (emailField &&
        record["field" + emailField.id]
          ?.toLocaleLowerCase()
          .includes(searchLower))
    );
  };

  const actionSize = useCallback(() => {
    let actions = 4;
    if (waitinglist) {
      actions++;
    }
    if (adminApproval && !adminApprovalRejected) {
      actions++;
    }
    return actions * 37;
  }, [waitinglist, adminApproval, adminApprovalRejected]);

  const activityFilterFn = (record: IRecord, activityId: number) => {
    const foundActivity = record.activities.find(
      (session: IRecord) => session.id === Number(activityId)
    );
    return typeof foundActivity !== "undefined";
  };

  const getRegistrationShort = (registration: IRecord) => {
    const firstnameField = fields.find(
      (field) => field.defaultfield === "firstname"
    );
    const lastnameField = fields.find(
      (field) => field.defaultfield === "lastname"
    );
    const emailField = fields.find((field) => field.defaultfield === "email");

    let str = "\n\n";
    if (firstnameField) {
      str =
        str +
        firstnameField.translations[firstnameField.defaultLanguage].title +
        ": " +
        registration.values["field" + firstnameField.id] +
        "\n";
    }
    if (lastnameField) {
      str =
        str +
        lastnameField.translations[lastnameField.defaultLanguage].title +
        ": " +
        registration.values["field" + lastnameField.id] +
        "\n";
    }
    if (emailField) {
      str =
        str +
        emailField.translations[emailField.defaultLanguage].title +
        ": " +
        registration.values["field" + emailField.id] +
        "\n";
    }
    return str;
  };

  const resendEmail = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmResendTitle"]);
    setConfirmmessage(
      translations["contacts.confirmResendMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch("/api/registrations/" + registration.id + "/resendEmail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
          }
        })
        .finally(() => {});
    });
    setOpenConfirm(true);
  };

  const adminApprovalReject = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmAdminApprovalRejectedTitle"]);
    setConfirmmessage(
      translations["contacts.confirmAdminApprovalRejectedMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch("/api/registrations/" + registration.id + "/adminReject", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {});
    });
    setOpenConfirm(true);
  };

  const adminApprovalGranted = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmAdminApprovalGrantedTitle"]);
    setConfirmmessage(
      translations["contacts.confirmAdminApprovalGrantedMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch("/api/registrations/" + registration.id + "/adminAproval", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {});
    });
    setOpenConfirm(true);
  };

  const acceptFromWaitinglist = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmAcceptFromWaitinglistTitle"]);
    setConfirmmessage(
      translations["contacts.confirmAcceptFromWaitinglistMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch(
        "/api/registrations/" + registration.id + "/acceptFromWaitinglist",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        });
    });
    setOpenConfirm(true);
  };

  useEffect(() => {
    setFilteredSessions([
      ...sessions.filter((sessions) => sessions.shownOnRegistration),
    ]);
  }, [sessions]);

  useEffect(() => {
    if (filters.length > 0) {
      //shallow copy of orginals rows
      let filteredRows = [...originalRows];

      filters.forEach((myFilter) => {
        filteredRows = filteredRows.filter((record) =>
          myFilter.filterFunction(record)
        );
      });

      setRows(filteredRows);
    } else {
      setRows(originalRows);
    }
  }, [filters]);

  useEffect(() => {
    if (debouncedSearch !== "") {
      setFilters((prevFilters) => {
        const filterIndex = prevFilters.findIndex(
          (filter) => filter.filterName === "search"
        );
        if (filterIndex !== -1) {
          prevFilters[filterIndex].filterFunction = (row: IRecord) =>
            nameFilter(row, debouncedSearch);
          return [...prevFilters];
        } else {
          return [
            ...prevFilters,
            {
              filterName: "search",
              filterFunction: (row: IRecord) =>
                nameFilter(row, debouncedSearch),
            },
          ];
        }
      });
    } else {
      setFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.filterName !== "search")
      );
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (activityFilter) {
      setFilters((prevFilters) => {
        const filterIndex = prevFilters.findIndex(
          (filter) => filter.filterName === "activity"
        );
        if (filterIndex !== -1) {
          prevFilters[filterIndex].filterFunction = (row: IRecord) =>
            activityFilterFn(row, activityFilter.id);
          return [...prevFilters];
        } else {
          return [
            ...prevFilters,
            {
              filterName: "activity",
              filterFunction: (row: IRecord) =>
                activityFilterFn(row, activityFilter.id),
            },
          ];
        }
      });
    } else {
      setFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.filterName !== "activity")
      );
    }
  }, [activityFilter]);

  useEffect(() => {
    setRows(
      registrations.map((reg) => {
        return {
          id: reg.id,
          activities: reg.activities,
          attendances: reg.attendances,
          registered: reg.registered,
          adminNote: reg.adminNote,
          flag: reg.flag,
          ...reg.values,
        };
      })
    );
  }, [registrations]);

  /*useEffect(() => {
    if (activityFilter) {
      const filteredRows = rows.filter((row) => {
        const foundActivity = row.activities.find((session: IRecord) => session.id === Number(activityFilter.id));
        return typeof foundActivity !== 'undefined';
      });
   
      setRows(filteredRows);
    } else {
      setRows(originalRows);
    }
  }, [activityFilter]);*/

  const getFlatObj = (obj: INewRecord) => {
    var result: INewRecord = {};
    fields.forEach((field) => {
      result["field" + field.id] = obj["field" + field.id] || "";
    });
    result.adminNote = obj.adminNote || "";
    return result;
  };

  const getFlatFlagObj = (obj: INewRecord) => {
    let result: INewRecord = {};
    result.flagNote = obj.flagNote || "";
    result.flag = obj.flag || "";
    return result;
  };

  const [flagInitialValues, setFlagInitialValues] = useState<INewRecord>(
    getFlatFlagObj({
      flagNote: "",
      flag: "",
    })
  );

  const [initialValues, setInitialValues] = useState<INewRecord>(
    getFlatObj({})
  );

  const constructValidationSchema = () => {
    const validationSchema: any = {};
    fields.forEach((field) => {
      const fieldValidationSchema = {
        required: field.required,
        type: field.type,
        min: field.min,
        max: field.max,
      };
      console.log(fieldValidationSchema);
      validationSchema["field" + field.id] = buildYupStringSchema(
        fieldValidationSchema
      );
    });
    console.log("validationSchema", validationSchema);
    return validationSchema;
  };

  const flagColors = ["#FF0000", "#FFA500", "#0000FF", "#800080"];

  const getColorClassName = (color: string): string => {
    const colorToClassName: { [key: string]: string } = {
      "#FF0000": "red-flag",
      "#FFA500": "orange-flag",
      "#0000FF": "blue-flag",
      "#800080": "purple-flag",
    };
    return colorToClassName[color] || "";
  };

  //generate style object ...
  const generateStyle = (color: string, className: string) => {
    return {
      [`& .${className}`]: {
        backgroundColor: alpha(color, 0.2),
        "&:hover": {
          backgroundColor: alpha(color, 0.3),
        },
        "&.Mui-selected": {
          backgroundColor: alpha(color, 0.4),
          "&:hover": {
            backgroundColor: alpha(color, 0.4),
          },
        },
      },
    };
  };

  const StyledDataGrid = styled(DataGrid)(() => ({
    ...Object.assign(
      {},
      ...flagColors.map((color) => {
        const className = getColorClassName(color);
        return generateStyle(color, className);
      })
    ),
  }));

  const generateRegistration = (values: INewRecord) => {
    var result: INewRecord = {};
    if (id > -1) {
      result.id = id;
    }
    result.adminNote = values.adminNote;

    let copiedvalues = { ...values };
    delete copiedvalues.adminNote;
    result.registrationfieldValues = { ...copiedvalues };
    result.eventId = eventId;
    result.widgetId = registrationWidgetId;
    result.attending = true;
    //result.activityIds = [];
    result.contact = !registered;
    //result.contactlistId = props.contactlistId;
    var emailfieldId = 0;
    fields.forEach((field) => {
      if (field.defaultfield === "email") {
        emailfieldId = field.id;
      }
    });
    result.email = copiedvalues["field" + emailfieldId];
    return result;
  };

  const deleteRegistration = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmDeleteTitle"]);
    setConfirmmessage(
      translations["contacts.confirmDeleteMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch("/api/registrations/" + registration.id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        });
    });
    setOpenConfirm(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(constructValidationSchema()),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id > -1) {
        fetch("/api/registrations/" + id, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(generateRegistration(values)),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            formik.resetForm({
              values: getFlatObj(values),
            });
            setOpen(false);
          });
      } else {
        fetch("/api/registrations/manual", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...generateRegistration(values),
            manual: true,
          }),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              setId(jsondata.data.id);
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            formik.resetForm({
              values: getFlatObj(values),
            });
            setOpen(false);
          });
      }
    },
  });

  const formikFlag = useFormik({
    initialValues: flagInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      fetch("/api/registrations/" + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, ...values }),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formikFlag.setSubmitting(false);
          formikFlag.resetForm({
            values: getFlatFlagObj(values),
          });
          setOpenFlag(false);
        });
    },
  });

  let columns: (GridColDef | GridActionsColDef)[] = [
    {
      field: "id",
      headerName: translations["contacts.nofields"],
      flex: 1,
      sortable: false,
    },
  ];

  //(!activityFilter || field.activities.includes(activityFilter))
  if (fields.length > 0) {
    columns = fields
      .filter(
        (field) =>
          field.type !== "divider" &&
          field.type !== "text" &&
          field.type !== "title"
      )
      .map((field) => {
        if (field.defaultfield === "group") {
          return {
            field: "field" + field.id,
            headerName: field.translations[field.defaultLanguage].title,
            flex: 1,
            editable: true,
            renderEditCell: (props: GridRenderEditCellParams) => {
              const { id, value, field } = props;
              const options =
                groupField?.translations[groupField.defaultLanguage].options;

              return (
                <Select
                  value={value}
                  MenuProps={
                    options && options.length > 10
                      ? {
                          MenuListProps: {
                            sx: {
                              display: "flex",
                              flexDirection: "row",
                              maxWidth: "200px",
                              flexWrap: "wrap",
                            },
                          },
                        }
                      : {}
                  }
                  onChange={(e) => {
                    props.api.setEditCellValue({
                      id,
                      field,
                      value: e.target.value,
                    });
                    let registration = registrations.find(
                      (registration) => registration.id === id
                    );
                    if (typeof registration !== "undefined") {
                      setId(registration.id);
                      let obj = getFlatObj({
                        ...registration.values,
                        adminNote: registration.adminNote,
                      } as INewRecord);

                      obj[field] = e.target.value;

                      fetch("/api/registrations/" + id, {
                        method: "PUT",
                        headers: {
                          Accept: "application/json",
                          Authorization:
                            "Bearer " + sessionStorage.getItem("jwt"),
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(generateRegistration(obj)),
                      });
                    }
                  }}
                >
                  {options?.map((option: string) => {
                    return (
                      <MenuItem key={option} value={option} sx={{ flex: 1 }}>
                        {option.replace("<br />", "\n")}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            },
            valueFormatter: (params) => {
              if (!params.value) {
                return params.value;
              }

              if (params.value.includes("<>")) {
                return params.value.split("<>").join(", ");
              }
            },
          };
        } else {
          return {
            field: "field" + field.id,
            headerName: field.translations[field.defaultLanguage].title,
            flex: 1,
            valueFormatter: (params) => {
              if (!params.value) {
                return params.value;
              }

              if (params.value.includes("<>")) {
                return params.value.split("<>").join(", ");
              }
            },
          };
        }
      });

    if (attendanceFilter !== null) {
      columns.push({
        headerName: translations["contacts.attendanceCheck"],
        flex: 1,
        field: "attendances",
        valueGetter: (params: GridValueGetterParams<IRecord>) => {
          if (params.row.attendances) {
            return (
              params.row.attendances.find((attendance: IRecord) => {
                return attendance.activityId === attendanceFilter.id;
              }) !== undefined
            );
          }
        },
        renderCell: (params: any) => {
          console.log(params.value);
          return (
            <Chip
              label={
                params.value
                  ? localeFormatDateTime(parseDateTime(params.value.checkDate))
                  : translations["attendance.notCheckedIn"]
              }
              color={params.value ? "primary" : "default"}
            />
          );
        },
      });
    }

    columns.push({
      field: "registered",
      flex: 1,
      headerName: translations["contacts.registration"],
      valueFormatter: (params: GridValueFormatterParams<IRecord>) => {
        return localeFormatDateTime(parseDateTime(params.value));
      },
    });
    if (filteredSessions && filteredSessions.length > 0) {
      columns.push({
        field: "activities",
        flex: 1,
        headerName: "Aktivitat",
        valueFormatter: (params: GridValueFormatterParams<IRecord>) => {
          const activityStrings = params.value.map((activity: IRecord) => {
            const foundActivity = filteredSessions.find(
              (session) => session.id === activity.id
            );
            if (foundActivity) {
              return foundActivity.translations[foundActivity.defaultLanguage]
                .title;
            }
            return "";
          });

          return activityStrings.join(", ");
        },
      });
    }

    columns.push({
      field: "actions",
      type: "actions",
      width: actionSize(),
      getActions: (params: GridRowParams) => {
        let items = [
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              let registration = registrations.find(
                (registration) => registration.id === params.id
              );
              if (typeof registration !== "undefined") {
                setId(registration.id);
                let obj = getFlatObj({
                  ...registration.values,
                  adminNote: registration.adminNote,
                } as INewRecord);
                formik.resetForm({
                  touched: {},
                  errors: {},
                });
                setInitialValues(obj);
                formik.setValues(obj, false);
                setMyDialogTitle(translations["contacts.editcontacttitle"]);
                setOpen(true);
              }
            }}
            label={translations["contacts.edit"]}
          />,
        ];

        if (waitinglist) {
          items.push(
            <GridActionsCellItem
              icon={<ContactMailIcon />}
              onClick={() => {
                let registration = registrations.find(
                  (registration) => registration.id === params.id
                );
                if (typeof registration !== "undefined") {
                  acceptFromWaitinglist(registration);
                }
              }}
              label={translations["contacts.acceptFromWaitinglist"]}
            />
          );
        } else if (adminApproval) {
          items.push(
            <GridActionsCellItem
              icon={<ContactMailIcon />}
              onClick={() => {
                let registration = registrations.find(
                  (registration) => registration.id === params.id
                );
                if (typeof registration !== "undefined") {
                  adminApprovalGranted(registration);
                }
              }}
              label={translations["contacts.adminApprovalGranted"]}
            />
          );
          if (!adminApprovalRejected) {
            items.push(
              <GridActionsCellItem
                icon={<BlockIcon />}
                onClick={() => {
                  let registration = registrations.find(
                    (registration) => registration.id === params.id
                  );
                  if (typeof registration !== "undefined") {
                    adminApprovalReject(registration);
                  }
                }}
                label={translations["contacts.adminApprovalRejected"]}
              />
            );
          }
        } else {
          items.push(
            <GridActionsCellItem
              icon={<MailIcon />}
              onClick={() => {
                let registration = registrations.find(
                  (registration) => registration.id === params.id
                );
                if (typeof registration !== "undefined") {
                  resendEmail(registration);
                }
              }}
              label={translations["contacts.resendEmail"]}
            />
          );
        }

        items.push(
          <GridActionsCellItem
            icon={<ClearIcon />}
            onClick={() => {
              let registration = registrations.find(
                (registration) => registration.id === params.id
              );
              if (typeof registration !== "undefined") {
                deleteRegistration(registration);
              }
            }}
            label={translations["contacts.delete"]}
          />
        );
        return items;
      },
    });
  }

  columns = [
    {
      field: "flags",
      type: "actions",
      width: 50,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={
            <FlagIcon
              sx={{
                color:
                  params.row.flag !== null && params.row.flag !== ""
                    ? params.row.flag
                    : null,
              }}
            />
          }
          onClick={() => {
            let registration = registrations.find(
              (registration) => registration.id === params.id
            );
            if (typeof registration !== "undefined") {
              setId(registration.id);
              let obj = getFlatFlagObj({
                flagNote: registration.flagNote || "",
                flag: registration.flag || "",
              });
              formik.resetForm({
                touched: {},
                errors: {},
              });
              setFlagInitialValues(obj);
              formikFlag.setValues(obj, false);
              setMyDialogTitle(" EDIT FLAG");
              setOpenFlag(true);
            }
          }}
          label={translations["contacts.flagEdit"]}
        />,
      ],
    },
    ...columns,
  ];

  const handleCellClick = useCallback(
    (params: GridCellParams, event: MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [setCellModesModel]
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const onCellEditStop = useCallback(
    (params: GridCellParams, event: MuiEvent) => {
      console.log(params);
      revalidator.revalidate();
    },
    []
  );

  return (
    <>
      <Box p={1} gap={1} display="flex" flexDirection="row">
        {!adminApproval && !waitinglist ? (
          <>
            <ContactImportControl
              registered={registered}
              onUploadComplete={() => {
                revalidator.revalidate();
              }}
              eventId={eventId}
              registrationFields={fields.filter(
                (field) =>
                  field.type !== "divider" &&
                  field.type !== "text" &&
                  field.type !== "title"
              )}
            />
            <Button
              variant="contained"
              onClick={() => {
                setId(-1);
                let obj = getFlatObj({});
                formik.resetForm({
                  values: obj,
                  touched: {},
                  errors: {},
                });
                setInitialValues(obj);
                formik.setValues(obj);
                setMyDialogTitle(translations["contacts.newcontacttitle"]);
                setOpen(true);
              }}
            >
              {translations["contacts.add"]}
            </Button>
          </>
        ) : null}
        {adminApproval && !adminApprovalRejected ? (
          <Button
            variant="contained"
            onClick={() => {
              let params = new URLSearchParams();
              params.append("eventId", "" + event.id);
              fetch("/api/registrations/adminAproveAll", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: params,
              })
                .then((res) => res.json())
                .then((jsondata) => {
                  if (jsondata.success) {
                    revalidator.revalidate();
                  }
                });
            }}
          >
            {translations["contacts.approveAll"]}
          </Button>
        ) : null}
        <TextField
          sx={{ width: 300 }}
          value={search}
          label={translations["contacts.search"]}
          onChange={(event: any) => {
            setSearch(event.target.value);
          }}
          size="small"
        />
        {filteredSessions && filteredSessions.length > 0 && (
          <Autocomplete
            sx={{ width: 300 }}
            options={
              filteredSessions && filteredSessions.length > 0
                ? filteredSessions
                : []
            }
            value={activityFilter}
            onChange={(event: any, value: IRecord | null) => {
              setActivityFilter(value);
            }}
            size="small"
            autoHighlight
            getOptionLabel={(option) =>
              option.translations[option.defaultLanguage].title
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.translations[option.defaultLanguage].title}
              </Box>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={translations["contacts.activities"]}
                  key={params.id}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              );
            }}
          />
        )}

        <Autocomplete
          sx={{ width: 300 }}
          options={sessions}
          value={attendanceFilter}
          onChange={(event: any, value: IRecord | null) => {
            setAttendanceFilter(value);
          }}
          size="small"
          autoHighlight
          getOptionLabel={(option) =>
            option.translations[option.defaultLanguage].title
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.translations[option.defaultLanguage].title}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translations["contacts.attendanceCheck"]}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <Button
          variant="contained"
          onClick={() => {
            let formData = new URLSearchParams();
            formData.append(
              "filter",
              JSON.stringify([
                { property: "eventId", value: eventId },
                { property: "registered", value: true },
                { property: "view", value: "AttendanceHistory" },
                { property: "search", value: debouncedSearch },
                {
                  property: "attendanceActivityId",
                  value: attendanceFilter?.id,
                },
                { property: "activityId", value: activityFilter?.id },
                { property: "waitinglist", value: waitinglist },
                { property: "adminApproval", value: adminApproval },
              ])
            );
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            fetch("/api/registrationexport/", {
              method: "post",
              headers: {
                Accept:
                  "application/json, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
              },
              body: formData,
            })
              .then((res) => res.blob())
              .then((blob) => {
                var file = window.URL.createObjectURL(blob);
                //window.location.assign(file);
                a.setAttribute("href", file);
                a.setAttribute(
                  "download",
                  event.translations[event.defaultLanguage].title +
                    " - " +
                    translations["contacts.registrations"]
                );
                a.click();
                window.URL.revokeObjectURL(file);
                document.body.removeChild(a);
              });
          }}
        >
          {translations["contacts.export"]}
        </Button>
      </Box>
      <StyledDataGrid
        getRowClassName={(params) => {
          return getColorClassName(params.row.flag);
        }}
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
        cellModesModel={cellModesModel}
        onCellEditStop={onCellEditStop}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&": { flex: 1 },
        }}
      ></StyledDataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{myDialogTitle}</DialogTitle>
        <DialogContent dividers={false}>
          <form>
            <Box display="flex" flexDirection="column">
              {fields.map((value) => (
                <Registrationfield
                  field={value}
                  formik={formik}
                  key={value.id}
                />
              ))}
              <Box key={"adminNote"}>
                <Box className="fieldlabel">
                  <Typography>{translations["contacts.adminNote"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    multiline={true}
                    minRows={3}
                    {...formik.getFieldProps("adminNote")}
                  ></TextField>
                </FormControl>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          manualSubmit={true}
          onCancel={() => {
            setOpen(false);
          }}
        ></SaveBar>
      </Dialog>
      <Dialog
        className="window"
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{confirmtitle}</DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText style={{ whiteSpace: "pre-line" }}>
            {confirmmessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              confirmFunction();
            }}
          >
            {translations["contacts.confirm"]}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            {translations["contacts.cancel"]}
          </Button>
        </DialogActions>
      </Dialog>
      {/*flag dialog*/}
      <Dialog
        className="window"
        open={openFlag}
        onClose={() => {
          setOpenFlag(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent dividers={false} style={{ whiteSpace: "pre-line" }}>
          <form>
            <Box display="flex" flexDirection="row" mb={1}>
              <Box className="fieldlabel" mr={"1rem"} sx={{ minWidth: 55 }}>
                <Typography>{translations["contact.flagNoteColor"]}</Typography>
              </Box>
              <Box flex="1">
                {/*}<Select
                  {...formikFlag.getFieldProps("flag")}
                  onChange={(e) => {
                    formikFlag.setFieldValue("flag", e.target.value);
                    if (e.target.value === '') {
                      formikFlag.setFieldValue('flagNote', '');
                    }
                  }}
                  displayEmpty={true}
                >{*/}
                <Select
                  name={"flag"}
                  value={formikFlag.values.flag}
                  autoWidth
                  onChange={(e) => {
                    formikFlag.setFieldValue("flag", e.target.value);
                    if (e.target.value === "") {
                      formikFlag.setFieldValue("flagNote", "");
                    }
                  }}
                  displayEmpty={true}
                >
                  {[
                    <MenuItem
                      key={0}
                      value={""}
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignContent: "center" }}>
                        <FlagIcon
                          sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                            marginRight: "0.5rem",
                          }}
                        />{" "}
                        {translations["contact.flagNoFlag"]}
                      </Box>
                    </MenuItem>,
                    ...flagColors.map((color: string, index) => {
                      return (
                        <MenuItem
                          key={index + 1}
                          value={color}
                          sx={{ flex: 1 }}
                        >
                          <FlagIcon
                            sx={{ width: 20, height: 20, color: color }}
                          />
                        </MenuItem>
                      );
                    }),
                  ]}
                </Select>
              </Box>
            </Box>
            {formikFlag.values.flag !== "" ? (
              <Box display="flex" flexDirection="row" mb={1}>
                <Box className="fieldlabel" mr={"1rem"} sx={{ minWidth: 55 }}>
                  <Typography>
                    {translations["contact.flagNoteLabel"]}
                  </Typography>
                </Box>
                <Box flex="1">
                  <TextField
                    fullWidth
                    multiline={true}
                    minRows={3}
                    {...formikFlag.getFieldProps("flagNote")}
                  ></TextField>
                </Box>
              </Box>
            ) : null}
          </form>
        </DialogContent>
        <SaveBar
          formik={formikFlag}
          manualSubmit={true}
          onCancel={() => {
            setOpenFlag(false);
          }}
        ></SaveBar>
      </Dialog>
      {/*END flag dialog*/}
    </>
  );
}

export default Registrations;
