import { Box, IconButton, ListItemButton } from "@mui/material";
import React from "react";
import { EventRecord } from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";
import {
  formatTime,
  localeFormatDate,
  parseDate,
  parseTime,
} from "../../../../../helpers/DateFunctions";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

const EventDetails = ({ eventRecord }: { eventRecord: EventRecord }) => {
  let startdate = localeFormatDate(parseDate(eventRecord.startdate));
  let enddate = localeFormatDate(parseDate(eventRecord.enddate));
  let starttime = formatTime(parseTime(eventRecord.starttime));
  let endtime = formatTime(parseTime(eventRecord.endtime));

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display={"flex"} justifyContent={"center"} alignItems="center">
          <CalendarTodayIcon sx={{ marginRight: "0.5rem" }} />
          <Box display="flex" flexDirection="column">
            <span>{" " + startdate}</span>
            {startdate !== enddate ? <span>{enddate}</span> : ""}{" "}
          </Box>
        </Box>
        {starttime !== null ? (
          <Box display="flex" sx={{ marginLeft: "0.5rem" }} alignItems="center">
            <AccessTimeIcon sx={{ marginRight: "0.5rem" }} />
            {" " + starttime}
            {starttime !== endtime && endtime !== null ? "-" + endtime : ""}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const EventListItemButton = ({
  onListItemClick,
  eventRecord,
  onSyncButtonClick,
}: {
  onListItemClick: Function;
  onSyncButtonClick: Function;
  eventRecord: EventRecord;
}) => {
  const translations = getTranslations();
  return (
    <ListItemButton
      disableRipple
      className="scanner-event-listitem"
      sx={[
        {
          backgroundColor: "#006e55",
          color: "white",
          padding: "1rem",
          marginBottom: "1rem",
          //flex: "1",
        },
        {
          "&:hover": {
            backgroundColor: "#005542",
          },
        },
      ]}
      onClick={() => {
        onListItemClick();
      }}
    >
      <Box
        justifyContent="space-between"
        display="flex"
        flexDirection="row"
        className="todoevent"
        flexWrap="wrap"
        alignItems="center"
        flex="1"
      >
        <Box display="flex" flex="1" flexDirection="column">
          <Box
            sx={{ mb: "1rem" }}
            display="flex"
            justifyContent="space-between"
          >
            <Box sx={{ fontWeight: "bold" }}>
              {eventRecord.translations[eventRecord.defaultLanguage].title}
            </Box>

            <Box>{translations[eventRecord.type]}</Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            className="eventdetails"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <EventDetails eventRecord={eventRecord} />
            <Box>{"#" + eventRecord.id} </Box>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={[
                {
                  color: "white",
                  padding: "0.5rem",
                },
                {
                  "&:hover": {
                    color: "orange",
                    backgroundColor: "white",
                  },
                },
              ]}
              onClick={(e) => onSyncButtonClick(e)}
            >
              <CloudSyncIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </ListItemButton>
  );
};

export default EventListItemButton;
