import { Box, SxProps } from "@mui/material";
import {
  ParentDragHandlers,
  WidgetRecord,
} from "../../../../../interfaces/Interfaces";
import React, { ReactComponentElement } from "react";
import { getTranslations } from "../../../../../translations/Translations";
import ParagraphField from "./widgets/ParagraphField";
import ImageField from "./widgets/ImageField";
import TitleField from "./widgets/TitleField";
import RegistrationField from "./widgets/RegistrationField";
import SubTitleField from "./widgets/SubTitleField";
import SectionWidget from "./widgets/SectionWidget";
import ButtonWidget from "./widgets/ButtonWidget";
import {
  createDefaultConfig,
  standardProps,
} from "./widgets/HOCs/withStandardStyles";
import IconListWidget from "./widgets/IconListWidget";
import SliderWidget from "./widgets/SliderWidget";
import VideoField from "./widgets/VideoField";

const translations = getTranslations();

export const webpageOptions = {
  type: "webpage",
  fieldOptions: ["backgroundColor", "containerWidth", "showNavigation"],
  defaultConfig: {
    containerWidth: "1170px",
    showNavigation: true,
  },
};

export const widgettypes = [
  {
    type: "registrationform",
    listElement: (
      <Box>{translations["website.widgettype.registrationform"]}</Box>
    ),
    htmlElement: RegistrationField,
  },
  {
    type: "title",
    listElement: <Box>{translations["website.widgettype.title"]}</Box>,
    fieldOptions: [
      "content",
      "textAlign",
      "fontSize",
      "fontColor",
      ...standardProps,
    ],
    defaultConfig: {
      ...createDefaultConfig(),
      fontSize: "50px",
      fontColor: "black",
      textAlign: "left",
    },
    htmlElement: TitleField,
  },
  {
    type: "subtitle",
    listElement: <Box>{translations["website.widgettype.subtitle"]}</Box>,
    fieldOptions: [
      "content",
      "textAlign",
      "fontSize",
      "fontColor",
      ...standardProps,
    ],
    defaultConfig: {
      ...createDefaultConfig(),
      textAlign: "left",
      fontColor: "black",
      margin: [0, 0, 16, 0],
    },
    htmlElement: SubTitleField,
  },
  {
    type: "paragraph",
    listElement: <Box>{translations["website.widgettype.paragraph"]}</Box>,
    defaultConfig: {
      ...createDefaultConfig(),
      fontSize: "16PX",
    },
    fieldOptions: ["content", "fontSize", ...standardProps],
    htmlElement: ParagraphField,
  },
  {
    type: "section",
    listElement: <Box>{translations["website.widgettype.section"]}</Box>,
    fieldOptions: [
      "columns",
      "backgroundColor",
      "backgroundImageSrc",
      "slantedTop",
      "slantedBottom",
      "columnMargin",
      "columnPadding",
      "columnBackgroundColor",
      "alignItems",
      ...standardProps,
    ],
    draggable: false,
    defaultConfig: {
      columns: "1",
      columnPadding: [0, 0, 0, 0],
      columnMargin: [0, 0, 0, 0],
      backgroundColor: "rgba(0,0,0,0)",
      columnBackgroundColor: "",
      backgroundImageSrc: "",
      slantedTop: false,
      slantedBottom: false,
      alignItems: "flex-start",
      ...createDefaultConfig(),
    },
    htmlElement: SectionWidget,
  },
  {
    type: "image",
    listElement: <Box>{translations["website.widgettype.image"]}</Box>,
    defaultConfig: {
      width: "auto",
      textAlign: "left",
      src: "",
      ...createDefaultConfig(),
    },
    fieldOptions: [
      "src",
      "textAlign",
      "width",
      "borderRadius",
      ...standardProps,
    ],
    htmlElement: ImageField,
  },
  {
    type: "button",
    listElement: <Box>{translations["website.widgettype.button"]}</Box>,
    fieldOptions: ["content", /*'section', */ "align", ...standardProps],
    defaultConfig: {
      align: "left",
      ...createDefaultConfig(),
    },
    htmlElement: ButtonWidget,
  },
  {
    type: "iconlist",
    listElement: <Box>{translations["website.widgettype.iconlist"]}</Box>,
    fieldOptions: ["listitems", "listimage", "fontColor", ...standardProps],
    defaultConfig: {
      listitems: [{ src: "test", text: "Item 1" }],
      fontColor: "black",
      ...createDefaultConfig(),
    },
    htmlElement: IconListWidget,
  },
  {
    type: "slider",
    listElement: <Box>Slider</Box>,
    fieldOptions: ["srcs"],
    defaultConfig: {
      srcs: [],
    },
    htmlElement: SliderWidget,
  },
  {
    type: "video",
    listElement: <Box>{translations["website.widgettype.video"]}</Box>,
    defaultConfig: {
      width: "auto",
      textAlign: "left",
      videoSrc: "",
      videoThumbSrc: "",
      ...createDefaultConfig(),
    },
    fieldOptions: ["videoSrc", "textAlign", "width", ...standardProps],
    htmlElement: VideoField,
  },
];

export const getDefaultJsonConfig = (type: string) => {
  const widgetSettings = getWidgetSettingsByType(type);

  if (widgetSettings && widgetSettings.defaultConfig) {
    return widgetSettings?.defaultConfig;
  } else {
    return {};
  }
};

const getWidgetSettingsByType = (type: string) => {
  return widgettypes.find((typeWidget) => typeWidget.type === type);
};

export const returnDefaultWebpageJsonConfig = (config: any) => {
  const parsedConfig = JSON.parse(config);
  let newConfig = { ...webpageOptions.defaultConfig, ...parsedConfig };
  return newConfig;
};

export const returnDefaultJsonConfig = (type: string, config: any) => {
  const widgetTypeConfig = getWidgetSettingsByType(type);
  let newConfig: any = { ...config };

  if (widgetTypeConfig && widgetTypeConfig.defaultConfig) {
    newConfig = { ...widgetTypeConfig.defaultConfig, ...config };
  }
  return newConfig;
};

const WidgetField = ({
  sx,
  widget,
  widgetChildren,
  childWidgetFunctions,
}: {
  sx?: SxProps;
  widget: WidgetRecord;
  widgetChildren?: WidgetRecord[];
  childWidgetFunctions?: {
    edit: Function;
    remove: Function;
    nestedDropFn?: Function;
    sortFn?: Function;
    testFn?: Function;
  };
}) => {
  const { type } = widget;
  const widgetConfig = widgettypes.find((w) => w.type === type);
  const WidgetComponent = widgetConfig && widgetConfig.htmlElement;
  return WidgetComponent ? (
    <Box className={`${type}-widget`}>
      <WidgetComponent
        widget={widget}
        childWidgets={widgetChildren}
        childWidgetFunctions={childWidgetFunctions}
      />
    </Box>
  ) : (
    <Box>{widget.type}</Box>
  );
};

export default WidgetField;
