import * as Yup from "yup";

import { ReactNode, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { StyledButton } from "../styledButton/StyledButton";
import {
  ActivityGroup,
  InitialValues,
  RegistrationField,
  ValidationConfig,
  ValidationTypes,
} from "../../Interfaces";
import { Field } from "../Fields/Field";
import { buildYupStringSchema, fetchData } from "../../Helpers";
import { useRegistrationData } from "../../hooks/useRegistrationData";
import { Element, scroller } from "react-scroll";
import React from "react";

const mapFieldToInitialValue = (type: string) => {
  switch (type) {
    case "terms":
    case "checkbox":
      return false;
    default:
      return "";
  }
};

const hasCustomYupString = (registrationField: RegistrationField) => {
  const customYupStrings = [
    {
      id: 145,
      validationString: Yup.string().when("field147", {
        is: (value: boolean) => {
          return value === false;
        },
        then: (schema) => schema.required("Pflichtfeld"),
      }),
    },
  ];
  return customYupStrings.find(
    (customYupString) => customYupString.id === registrationField.id
  );
};

const buildInitialValuesAndValidationSchema = (
  registrationFields: RegistrationField[],
  groupedActivities: ActivityGroup[]
) => {
  const initialValues: InitialValues = {};
  const validationSchema: ValidationConfig<any> = {};

  registrationFields.forEach((registrationField) => {
    if (registrationField.type !== "text") {
      const fieldName = "field" + registrationField.id;
      initialValues[fieldName] = mapFieldToInitialValue(registrationField.type);
      const customYupStringSchema = hasCustomYupString(registrationField);

      const defaultValidation = () => {
        const fieldValidationSchema: ValidationTypes = {
          required: registrationField.required,
          type: registrationField.type,
          min: registrationField.min,
          max: registrationField.max,
        };

        validationSchema[fieldName] = buildYupStringSchema(
          fieldValidationSchema
        );
      };

      if (customYupStringSchema) {
        validationSchema[fieldName] = customYupStringSchema.validationString;
      } else {
        if (
          registrationField.config &&
          registrationField.config.parent !== ""
        ) {
          const parentId = registrationField.config.parent;
          const parentField = registrationFields.find(
            (regField) => regField.id === Number(parentId)
          );
          if (parentField) {
            switch (parentField.type) {
              case "checkbox":
              case "terms":
                const parentFieldName = "field" + parentId;
                if (registrationField.config.parentRequired) {
                  validationSchema[fieldName] = Yup.string().when(
                    parentFieldName,
                    {
                      is: (value: boolean) => {
                        return value === true;
                      },
                      then: (schema) => schema.required("Pflichtfeld"),
                    }
                  );
                } else {
                  defaultValidation();
                }
                break;
              default:
                defaultValidation();
                break;
            }
          }
        } else {
          defaultValidation();
        }
      }
    }
  });

  /*groupedActivities.forEach((groupedActivity) => {
        const fieldName = 'group' + groupedActivity.blockId;
        initialValues[fieldName] = '';
        const fieldValidationSchema: ValidationTypes = {
            required: true,
            type: 'radio'
        };
        validationSchema[fieldName] = buildYupStringSchema(fieldValidationSchema);
    })*/

  console.log(validationSchema);
  return { initialValues, validationSchema };
};

const shouldRenderField = (
  registrationField: RegistrationField,
  values: Record<string, any>,
  allregistrationfields: RegistrationField[]
) => {
  if (registrationField.config.parent) {
    const { parent, parentValue, parentMode } = registrationField.config;
    const parentField = allregistrationfields.find(
      (regField) => regField.id === Number(parent)
    );

    const defaultCheck = () => {
      if (!parentValue) return true;
      const parentFieldValue = values[`field${parent}`];

      if (parentFieldValue) {
        return parentFieldValue === parentValue;
      }
    };

    const checkboxCheck = () => {
      if (!parentValue) return true;
      const parentFieldValue = values[`field${parent}`];

      if (parentMode === "show") {
        return (
          (parentValue === "0" && parentFieldValue) ||
          (parentValue === "1" && !parentFieldValue)
        );
      } else {
        return (
          (parentValue === "0" && !parentFieldValue) ||
          (parentValue === "1" && parentFieldValue)
        );
      }
    };

    if (parentField) {
      switch (parentField.type) {
        case "checkbox":
          return checkboxCheck();
        default:
          return defaultCheck();
      }
    }
  }
  return true;
};

const getFieldOptions = (registrationField: RegistrationField) => {
  const defaultLanguage = registrationField.defaultLanguage;
  const options = registrationField.translations[defaultLanguage].options;

  return options && Array.isArray(options)
    ? options.map((option: string) => ({ value: option, label: option }))
    : [];
};

const getTitle = (registrationField: RegistrationField) => {
  const defaultLanguage = registrationField.defaultLanguage;
  return registrationField.translations[defaultLanguage].title;
};

const RegistrationForm = (props: {
  anchorName?: string;
  eventId: string;
  registrationWidgetId: any;
}) => {
  const { anchorName, eventId, registrationWidgetId } = props;

  const scrollToElement = () => {
    if (anchorName) {
      scroller.scrollTo(anchorName, {
        duration: 5000,
      });
    }
  };
  const [isSynced, setIsSynced] = useState<boolean>(false);
  //const [isSynced, setIsSynced] = useState<boolean>(true);
  const [eventLanguage, setEventLanguage] = useState("");
  const {
    registrationFields,
    dataFetched,
    blocks,
    filteredActivities,
    groupedActivities,
  } = useRegistrationData(eventId);
  const { initialValues, validationSchema } =
    buildInitialValuesAndValidationSchema(
      registrationFields,
      groupedActivities
    );

  const mapRegistrationFieldType = (type: string) => {
    const typeMappings = {
      title: "title",
      text: "type",
      checkbox: "checkbox",
      terms: "terms",
      textarea: "textarea",
      textfield: "text",
      emailfield: "email",
      radiobuttons: "radio",
      combobox: "combobox",
      multivalues: "multivalues",
    };
    return typeMappings[type as keyof typeof typeMappings] || "text";
  };

  interface IdObject {
    fieldId: number;
    before?: JSX.Element;
    after?: JSX.Element;
  }

  const StyledContainer = (props: { children: ReactNode }) => {
    const { children } = props;
    return (
      <Paper
        elevation={0}
        sx={{ padding: "1rem 1rem 0 1rem", marginTop: "1rem" }}
      >
        {children}
      </Paper>
    );
  };

  const ids: IdObject[] = [
    {
      fieldId: 39,
    },
    {
      fieldId: 40,
    },
  ];

  const IdMapping = (
    id: number,
    key: "before" | "after",
    values: InitialValues
  ): JSX.Element | null => {
    const foundId = ids.find((e) => e.fieldId === id && e.hasOwnProperty(key));

    if (foundId && foundId[key] && typeof foundId[key] !== "undefined") {
      const cmp = foundId[key]!;
      const componentWithValues = React.cloneElement(cmp, { values: values });
      return <StyledContainer>{componentWithValues}</StyledContainer>;
    } else {
      return null;
    }
  };

  const BeforeContent = ({
    id,
    values,
  }: {
    id: number;
    values: InitialValues;
  }) => {
    return IdMapping(id, "before", values);
  };

  const AfterContent = ({
    id,
    values,
  }: {
    id: number;
    values: InitialValues;
  }) => {
    return IdMapping(id, "after", values);
  };

  const getLabel = (field: RegistrationField) => {
    if (field.type === "checkbox") {
      return field.config.checkboxlabel;
    }
    return field.translations[eventLanguage || field.defaultLanguage].title;
  };

  const getRequired = (field: RegistrationField, validationSchema: any) => {
    let parentRequired = false;

    if (field.config && field.config.parentRequired) {
      parentRequired = field.config.parentRequired;
    }
    return field.required || parentRequired;
  };

  const getConfig = (field: RegistrationField) => {
    if (field.config) {
      return field.config;
    } else {
      return null;
    }
  };

  return (
    <>
      {anchorName ? <Element name={anchorName}></Element> : null}
      {!dataFetched && <div>loading Form</div>}
      {dataFetched && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values", values);
            const { fieldValues, activityIds } = Object.keys(values).reduce(
              (result, key) => {
                if (key.startsWith("field")) {
                  result.fieldValues[key] = values[key];
                } else if (key.startsWith("group")) {
                  result.activityIds.push(Number(values[key]));
                }
                return result;
              },
              { fieldValues: {} as InitialValues, activityIds: [] as Number[] }
            );

            const response = {
              eventId: Number(eventId),
              attending: true,
              registrationfieldValues: fieldValues,
              activityIds: activityIds,
            };
            //https://dev.clicla.me/api/registrations/
            fetchData(
              "POST",
              "/api/registrations/",
              (serverResponse: any) => {
                setSubmitting(false);
                setIsSynced(true);
                scrollToElement();
              },
              response
            );
          }}
        >
          {({ values, resetForm }) => {
            console.log("eventId", eventId);
            if (isSynced) {
              return (
                <Paper sx={{ padding: "1rem", paddingTop: "2rem" }}>
                  <Box
                    display="flex"
                    alignContent={"center"}
                    textAlign="center"
                    flexDirection={"column"}
                  >
                    <Box>
                      <Typography mb={2} variant={"h2"}>
                        Wir haben Ihre Anmeldung erhalten!
                      </Typography>
                      <Typography mb={2} variant={"body1"}>
                        Prüfen Sie Ihre Mails auf Aktualisierungen.
                      </Typography>
                    </Box>
                    {/*}<Box>
                                    <StyledButton onClick={() => {
                                        resetForm();
                                        setIsSynced(false);
                                    }}>eine weitere Registrierung</StyledButton></Box>*/}
                  </Box>
                </Paper>
              );
            } else {
              return (
                <Form>
                  <Grid container justifyContent={"center"}>
                    {registrationFields &&
                      registrationFields
                        .filter(
                          (field) => field.widgetId === registrationWidgetId
                        )
                        .map((registrationField, index) => {
                          const shouldRender = shouldRenderField(
                            registrationField,
                            values,
                            registrationFields
                          );

                          if (!shouldRender) {
                            values["field" + registrationField.id] = ""; // Clear the value
                          }

                          return shouldRender ? (
                            <Grid item xs={12} key={index}>
                              <Field
                                fieldId={registrationField.id}
                                label={getLabel(registrationField)}
                                config={getConfig(registrationField)}
                                name={"field" + registrationField.id.toString()}
                                type={mapRegistrationFieldType(
                                  registrationField.type
                                )}
                                required={getRequired(
                                  registrationField,
                                  validationSchema
                                )}
                                title={getTitle(registrationField)}
                                options={getFieldOptions(registrationField)}
                                beforeContent={
                                  <BeforeContent
                                    id={registrationField.id}
                                    values={values}
                                  />
                                }
                                afterContent={
                                  <AfterContent
                                    id={registrationField.id}
                                    values={values}
                                  />
                                }
                              />
                            </Grid>
                          ) : null; // Render null if shouldRender is false
                        })}

                    {/* render activities */}
                    {/*}
                                        <Grid container mb={'1rem'}>
                                            {groupedActivities && groupedActivities.map((activityGroup, index) => {
                                                const matchingBlock = blocks.find((block) => block.id === activityGroup.blockId);
                                                const blockTitle = matchingBlock ? matchingBlock.translations.de.title : '';
                                                const blockDescription = matchingBlock ? matchingBlock.translations.de.description : '';
                                                return (
                                                    <Grid item xs={12} key={index}>
                                                        <Field
                                                            fieldId={activityGroup.blockId}
                                                            label={`${blockTitle}`}
                                                            type={'radio'}
                                                            description={blockDescription}
                                                            name={`group${activityGroup.blockId}`}
                                                            options={activityGroup.activities.map((activity) => ({
                                                                value: activity.id.toString(),
                                                                label: activity.translations.de.title,
                                                            }))}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        {*/}
                    <StyledButton
                      sx={
                        Number(eventId) === 29
                          ? {
                              backgroundColor: "#d2ecf2",
                              color: "#021f4d",
                              "&:hover": { backgroundColor: "#96cad6" },
                            }
                          : {}
                      }
                      type="submit"
                    >
                      <Typography variant="body1">Anmelden</Typography>
                    </StyledButton>
                  </Grid>
                </Form>
              );
            }
          }}
        </Formik>
      )}
    </>
  );
};

export default RegistrationForm;
