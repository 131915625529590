import React, { useState, useEffect, useRef } from "react";
import { getMonths } from "../../translations/Translations";
import TopToolbar from "../../components/TopToolbar";
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Button,
  Chip,
  ListItemIcon,
} from "@mui/material";
import {
  CalendarComponent,
  DayHeader,
} from "../../components/Calendar/Calendar";

import { Box } from "@mui/material";
import dayjs from "dayjs";
import { getTranslations } from "../../translations/Translations";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";

import {
  createEvent,
  createWebpage,
  createRegistrationField,
  createRegistrationFormWidget,
  createApellaRegistrationFields,
} from "../../helpers/RequestFunctions";

import { lightenColor } from "../../helpers/ColorFunctions";
import { EventRecord, MenuObject } from "../../interfaces/Interfaces";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DisplayErrors } from "../../components/DisplayErrors";
import { DatePicker, TimeField } from "@mui/x-date-pickers";
import {
  formatDate,
  formatTime,
  isValidDateValue,
  parseDate,
} from "../../helpers/DateFunctions";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import { styled } from "@mui/material/styles";
import ContextMenu from "../../components/ContextMenu";

import VisibilityIcon from "@mui/icons-material/Visibility";

const translations = getTranslations();

interface Day {
  dateString: string;
  dayOfMonth: number;
  isCurrentMonth: boolean;
  isPreviousMonth?: boolean;
  isNextMonth?: boolean;
}

function DayCmp(props: {
  day: Day;
  onDayClick: Function;
  baseColor: string;
  events: EventRecord[];
  selectedDay: Day | null;
  selectedEvent: EventRecord | null;
  onEventClick: Function;
  mappedEvents: string[][];
  selectedDayRef: React.RefObject<HTMLElement | null>;
}) {
  const {
    onDayClick,
    day,
    events,
    mappedEvents,
    baseColor,
    selectedDay,
    selectedDayRef,
    onEventClick,
    selectedEvent,
  } = props;
  let dayEvents = [];
  for (let i = 0; i < mappedEvents.length; i++) {
    if (mappedEvents[i].includes(day.dateString)) {
      dayEvents.push(events[i]);
    }
  }

  dayEvents.sort((a, b) => {
    const indexA = events.indexOf(a);
    const indexB = events.indexOf(b);
    return mappedEvents[indexB].length - mappedEvents[indexA].length;
  });

  const eventColors = [
    baseColor,
    lightenColor(baseColor, 80),
    lightenColor(baseColor, 60),
    lightenColor(baseColor, 40),
    lightenColor(baseColor, 20),
  ];
  const selectedDayBackgroundColor = "red";

  return (
    <Box
      ref={selectedDayRef}
      className={
        selectedDay && selectedDay.dateString === day.dateString
          ? "day-content-wrapper selected"
          : "day-content-wrapper"
      }
      onClick={() => {
        onDayClick();
      }}
    >
      <DayHeader day={day} />

      {dayEvents.length > 0 &&
        dayEvents.map((myEvent, index) => {
          const color = eventColors[index % eventColors.length];
          const firstEvent = index === 0;
          const isFirstDay =
            mappedEvents[events.indexOf(myEvent)][0] === day.dateString;
          const isLastDay =
            mappedEvents[events.indexOf(myEvent)][
              mappedEvents[events.indexOf(myEvent)].length - 1
            ] === day.dateString;
          const isOnlyDay = mappedEvents[events.indexOf(myEvent)].length === 1;

          let eventClasses = `event-item ${isFirstDay ? "first-day" : ""} ${
            isLastDay ? "last-day" : ""
          }`;

          if (isOnlyDay) {
            eventClasses = `event-item single-day`;
          }

          if (firstEvent) {
            eventClasses = eventClasses + " first-event";
          }

          if (myEvent === selectedEvent) {
            eventClasses = eventClasses + " selected-event";
          }

          return (
            <Box
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                onEventClick(e, myEvent);
              }}
              className={eventClasses}
              style={{ backgroundColor: color }}
            >
              {myEvent.translations[myEvent.defaultLanguage].title}
            </Box>
          );
        })}
    </Box>
  );
}

function getDatesBetween(startdate: string, enddate: string): string[] {
  const dates = [];
  let currentDate = dayjs(startdate);
  while (currentDate <= dayjs(enddate)) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return dates;
}

function Calendar() {
  const [yearAndMonth, setYearAndMonth] = useState<number[]>([
    new Date().getFullYear(),
    new Date().getMonth() + 1,
  ]);
  const [year, month] = yearAndMonth;
  const [events, setEvents] = useState<EventRecord[]>([]);
  const [mappedEvents, setMappedEvents] = useState<string[][]>([]);

  const [selectedDay, setSelectedDay] = useState<Day | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventRecord | null>(null);

  const [selectedDayConfig, setSelectedDayConfig] = useState<MenuObject>({
    open: false,
    location: null,
  });

  const [selectedEventConfig, setSelectedEventConfig] = useState<MenuObject>({
    open: false,
    location: null,
  });

  /*const [selectedDayLocation, setSelectedDayLocation] =
    useState<DOMRect | null>(null);*/
  /*const [selectedEventLocation, setSelectedEventLocation] = useState<DOMRect | null>(null);*/
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const auth = useAuth();
  const navFn = useNavigate();

  const selectedDayRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (
      selectedDayRef &&
      selectedDayRef.current &&
      selectedDayRef.current !== null &&
      typeof selectedDayRef.current !== "undefined"
    ) {
      setSelectedDayConfig((prev) => ({
        ...prev,
        location: selectedDayRef.current!.getBoundingClientRect(),
      }));
    }
  }, [selectedDay]);

  interface formValues {
    title: string;
    type: string;
    startdate: string | null;
    enddate: string | null;
    starttime: string | null;
    endtime: string | null;
  }

  const handleSubmit = async (values: formValues) => {
    const createNewEvent = async (): Promise<void> => {
      try {
        const event = await createEvent(auth, {
          defaultLanguage: "de",
          language: "de",
          theme: "Apella",
          ...values,
        });

        const eventId = event.id;

        const webpage1 = await createWebpage(eventId, "Neues Seite");
        const webpage2 = await createWebpage(eventId, "Registrieren");

        const widget1 = await createRegistrationFormWidget(
          eventId,
          webpage1.id
        );
        await createRegistrationFormWidget(eventId, webpage2.id);

        //check if apella ...
        createApellaRegistrationFields(eventId, widget1.id);

        navFn("/admin/events/" + eventId + "/", {
          replace: false,
        });
      } catch (error) {
        console.error(error);
      }
    };
    createNewEvent();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      type: "SEMINAR",
      startdate: selectedDay?.dateString
        ? new Date(selectedDay.dateString)
        : null,
      enddate: null,
      starttime: null,
      endtime: null,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      title: Yup.mixed().required(translations["errors.requiredfield"]),
      startdate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"]),
      enddate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"])
        .test(
          "enddate-after-startdate",
          translations["eventdetailstab.errors.startdatebeforeenddate"],
          function (enddate) {
            const { startdate } = this.parent;
            if (!startdate) return true;
            const startDate = new Date(startdate);
            const endDate = new Date(enddate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            return endDate >= startDate;
          }
        ),
      /*.when(["startdate"], ([startdate], schema) => {
          if (isValidDateValue(startdate)) {
            return schema.min(
              startdate,
              translations["eventdetailstab.errors.startdatebeforeenddate"]
            );
          } else {
            return schema;
          }
        })*/ starttime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired(),
      endtime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired()
        .when(
          ["startdate", "enddate", "starttime"],
          ([startdate, enddate, starttime], schema) => {
            if (
              isValidDateValue(startdate) &&
              isValidDateValue(enddate) &&
              isValidDateValue(starttime) &&
              formatDate(startdate) === formatDate(enddate)
            ) {
              return schema.min(
                starttime,
                translations["eventdetailstab.errors.startdatebeforeenddate"]
              );
            } else {
              return schema;
            }
          }
        ),
    }),
    onSubmit: (values) => {
      handleSubmit({
        ...values,
        startdate: formatDate(values.startdate),
        enddate: formatDate(values.enddate),
        starttime: formatTime(values.starttime) || "",
        endtime: formatTime(values.endtime) || "",
      });
    },
  });

  const colorEventType = (eventType: string): "default" | "primary" => {
    if (formik.values.type === eventType) {
      return "primary";
    }
    return "default";
  };

  useEffect(() => {
    const controller = new AbortController();
    let url: string = "/api/events";

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setEvents(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    let mappedEvents = events.map((eventRecord) => {
      let start = eventRecord.startdate;
      let end = eventRecord.enddate;
      if (start !== null && end !== null) {
        return getDatesBetween(start, end);
      } else {
        if (start === null) {
          return [end];
        } else {
          return [start];
        }
      }
    });

    setMappedEvents(mappedEvents);
  }, [events]);

  const handleNextMonth = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if (nextMonth === 13) {
      nextMonth = 1;
      nextYear = year + 1;
    }
    setYearAndMonth([nextYear, nextMonth]);
  };

  const handleLastMonth = () => {
    let lastYear = year;
    let lastMonth = month - 1;
    if (lastMonth === 0) {
      lastMonth = 12;
      lastYear = year - 1;
    }
    setYearAndMonth([lastYear, lastMonth]);
  };

  const handleOnClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TopToolbar
        title={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className="calendar-controls"
            mt={1}
            ml={2}
            mr={2}
            minWidth="300px"
          >
            <Box display="flex" flexDirection="row">
              <Typography className="calendar-month" fontSize={"24px"}>
                {`${getMonths()[month - 1]} `}
              </Typography>
              <Typography fontSize={"24px"} ml={1}>
                {`${year}`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <IconButton onClick={handleLastMonth}>
                <ArrowUpwardIcon sx={{ color: "#006e55" }} />
              </IconButton>
              <IconButton onClick={handleNextMonth}>
                <ArrowDownwardIcon sx={{ color: "#006e55" }} />
              </IconButton>
            </Box>
          </Box>
        }
      />
      <Box sx={{ padding: "0 1rem 1rem 1rem" }} flex="1">
        <CalendarComponent
          yearAndMonth={yearAndMonth}
          onYearAndMonthChange={setYearAndMonth}
          renderDay={(day: Day, generateClassNames: Function) => {
            return (
              <div key={day.dateString} className={generateClassNames(day)}>
                <DayCmp
                  onDayClick={() => {
                    setSelectedEvent(null);
                    setSelectedDay((prev) => {
                      if (prev) {
                        return null;
                      } else {
                        return day;
                      }
                    });
                  }}
                  onEventClick={(e: React.MouseEvent, event: EventRecord) => {
                    setSelectedDay(null);
                    setSelectedEvent((prev) => {
                      if (prev) {
                        if (prev === event) {
                          return null;
                        } else {
                          return event;
                        }
                      } else {
                        return event;
                      }
                    });
                    const target = e.currentTarget;
                    if (target !== null) {
                      setSelectedEventConfig((prev) => ({
                        ...prev,
                        location: target.getBoundingClientRect(),
                      }));
                    }
                  }}
                  selectedEvent={selectedEvent}
                  day={day}
                  baseColor={"#006e55"}
                  events={events}
                  mappedEvents={mappedEvents}
                  selectedDay={selectedDay}
                  selectedDayRef={
                    (day.dateString === (selectedDay && selectedDay.dateString)
                      ? selectedDayRef
                      : null) as React.RefObject<HTMLElement | null>
                  }
                />
              </div>
            );
          }}
        />
      </Box>
      {selectedDay && selectedDayConfig.location !== null && (
        <ContextMenu
          config={[selectedDayConfig, setSelectedDayConfig]}
          children={
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  component="a"
                  href="#simple-list"
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  <ListItemText primary={translations["dashboard.newevent"]} />
                </ListItemButton>
              </ListItem>
            </List>
          }
        />
      )}
      {selectedEvent && selectedEventConfig.location && (
        <ContextMenu
          config={[selectedEventConfig, setSelectedEventConfig]}
          children={
            <List>
              <ListItemButton
                onClick={() => {
                  console.log("view clicked");
                  //bring to event in browser
                }}
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary={translations["calendar.viewEvent"]} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navFn("/admin/events/" + selectedEvent.id + "/", {
                    replace: false,
                  });
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={translations["calendar.editEvent"]} />
              </ListItemButton>
            </List>
          }
        />
      )}
      <Dialog
        className="window"
        open={dialogOpen}
        onClose={handleOnClose}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{translations["dashboard.newevent"]}</Box>
            <Box>
              <IconButton onClick={handleOnClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers={false}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              style={{ marginBottom: "1rem" }}
            >
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.title"]}</Typography>
              </Box>
              <FormControl fullWidth>
                <TextField
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  {...formik.getFieldProps("title")}
                ></TextField>
                <DisplayErrors
                  name="title"
                  touched={Boolean(formik.touched.title)}
                  errors={formik.errors.title}
                />
              </FormControl>
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              style={{ marginBottom: "1rem" }}
            >
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.type"]}</Typography>
              </Box>
              <Box display="flex" flexDirection={"row"} flexWrap="wrap" gap={1}>
                <Chip
                  label={translations["WEBINAR"]}
                  color={colorEventType("WEBINAR")}
                  onClick={() => {
                    formik.setFieldValue("type", "WEBINAR");
                  }}
                />
                <Chip
                  label={translations["SEMINAR"]}
                  color={colorEventType("SEMINAR")}
                  onClick={() => {
                    formik.setFieldValue("type", "SEMINAR");
                  }}
                />
                <Chip
                  label={translations["TRAINING"]}
                  color={colorEventType("TRAINING")}
                  onClick={() => {
                    formik.setFieldValue("type", "TRAINING");
                  }}
                />
                <Chip
                  label={translations["WORKSHOP"]}
                  color={colorEventType("WORKSHOP")}
                  onClick={() => {
                    formik.setFieldValue("type", "WORKSHOP");
                  }}
                />
                <Chip
                  label={translations["PRESENTATION"]}
                  color={colorEventType("PRESENTATION")}
                  onClick={() => {
                    formik.setFieldValue("type", "PRESENTATION");
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              style={{ marginBottom: "1rem" }}
            >
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.start"]}</Typography>
              </Box>
              <FormControl>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("startdate", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "startdate",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("startdate", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.startdate && formik.errors.startdate
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.startdate}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "starttime",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("starttime", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.starttime && formik.errors.starttime
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.starttime}
                  />
                </Box>
                <DisplayErrors
                  name="startdate"
                  touched={Boolean(formik.touched.startdate)}
                  errors={formik.errors.startdate}
                />
                <DisplayErrors
                  name="starttime"
                  touched={Boolean(formik.touched.starttime)}
                  errors={formik.errors.starttime}
                />
              </FormControl>
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              style={{ marginBottom: "1rem" }}
            >
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.end"]}</Typography>
              </Box>
              <FormControl>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("enddate", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "enddate",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("enddate", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.enddate && formik.errors.enddate
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.enddate}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "endtime",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("endtime", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.endtime && formik.errors.endtime
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.endtime}
                  />
                </Box>
                <DisplayErrors
                  name="enddate"
                  touched={Boolean(formik.touched.enddate)}
                  errors={formik.errors.enddate}
                />
                <DisplayErrors
                  name="endtime"
                  touched={Boolean(formik.touched.endtime)}
                  errors={formik.errors.endtime}
                />
              </FormControl>
            </Box>
            <Button
              variant="contained"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.validateForm().then(() => {
                  if (formik.isValid) {
                    formik.submitForm();
                  }
                });
              }}
            >
              {translations["savebar.save"]}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Calendar;
