import { useField } from "formik";
import { MyRadioInputGroup } from "../../Interfaces";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

export const RadioInputGroup = (props: MyRadioInputGroup) => {
  const {
    label,
    options,
    description,
    beforeContent,
    afterContent,
    ...inputProps
  } = props;
  const [field, meta] = useField(inputProps);

  //Overwrite for event ...
  console.log("field", field);
  console.log("meta", meta);

  //Exception for BaV
  if (field && field.name === "field270") {
    const currentDate = new Date();
    const earlyBirdDate = new Date("2025-01-18");
    currentDate.setHours(0, 0, 0, 0);
    earlyBirdDate.setHours(0, 0, 0, 0);

    const getDisabledStatus = (index: number) => {
      if (currentDate <= earlyBirdDate) {
        return index === 2 || index === 3;
      } else {
        return index === 0 || index === 1;
      }
    };

    return (
      <Paper>
        {beforeContent}
        <Box sx={{ padding: "1rem" }}>
          <FormControl error={!!(meta.touched && meta.error)}>
            <FormLabel
              sx={{ color: "var(--color-primary)", fontWeight: "bold" }}
            >
              {label}
            </FormLabel>
            {description && (
              <Typography variant="body1">{description}</Typography>
            )}
            <RadioGroup row {...field} sx={{ flexDirection: "column" }}>
              {options.map((option, index) => {
                const isDisabled = getDisabledStatus(index); // Get disabled status
                if (isDisabled) {
                  return null;
                }
                return (
                  <FormControlLabel
                    control={<Radio />}
                    key={index}
                    value={option.value}
                    sx={{ display: "block" }}
                    label={
                      <span
                        style={{
                          textDecoration: isDisabled ? "line-through" : "none",
                          color: isDisabled ? "red" : "",
                        }}
                      >
                        {option.label}
                      </span>
                    }
                    disabled={isDisabled}
                  />
                );
              })}
            </RadioGroup>
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        </Box>
        {afterContent}
      </Paper>
    );
  } else {
    return (
      <Paper>
        {beforeContent}
        <Box sx={{ padding: "1rem" }}>
          <FormControl error={!!(meta.touched && meta.error)}>
            <FormLabel
              sx={{ color: "var(--color-primary)", fontWeight: "bold" }}
            >
              {label}
            </FormLabel>
            {description && (
              <Typography variant="body1">{description}</Typography>
            )}
            <RadioGroup row {...field}>
              {options.map((option, index) => (
                <FormControlLabel
                  control={<Radio />}
                  key={index}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        </Box>
        {afterContent}
      </Paper>
    );
  }
};
